import React,  { useEffect } from 'react';
import './App.css';

// Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
import {Amplify} from 'aws-amplify';

// Imports the Authenticator and withAuthenticator components from '@aws-amplify/ui-react'.
// Authenticator is a React component that provides a ready-to-use sign-in and sign-up UI.
// withAuthenticator is a higher-order component that wraps your app component to enforce authentication.
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import '@aws-amplify/ui-react/styles.css';

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import awsExports from './aws-exports';

// Imports the Quiz component from Quiz.js for use in this file.
//import Quiz from './Quiz';

import { fetchAuthSession } from '@aws-amplify/auth';

// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure(awsExports);

function App() {

  // const test = () => {
  //   console.log('in test')
  // };

  const callAPI = async () => {
    console.log('in callAPI');
    try {
      const response = await fetch('https://c1zbjfmjpk.execute-api.us-east-1.amazonaws.com/qa');
      if (response.ok) {
        const data = await response.json();
        console.log('api call made');
        console.log(data);
      } else {
        console.error("Failed to archive form");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const getIdToken = async () => {
    try {
      // Get the current authenticated session
     
      console.log('Before getting session');

      const session = await fetchAuthSession({ bypassCache: false });
      
      console.log('After getting session');
  
      // Extract the ID token from the session
      const idToken = session.tokens.idToken;
  
      console.log('idToken: ', idToken);
      console.log('idToken to string ', idToken.toString());
      const idTokenString = idToken.toString();

      const response = await fetch('https://c1zbjfmjpk.execute-api.us-east-1.amazonaws.com/qa', 
        {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idTokenString}`, // Pass the ID token in the Authorization header
        }
      },
      );
      if (response.ok) {
        const data = await response.json();
        console.log('api call made');
        console.log(data);
      } else {
        console.error("Failed to archive form");
      }

      //console.log('Payload:', payload);
      
      // You can access specific claims from the payload, e.g., email or username
      //console.log('Email:', payload.email);
    } catch (error) {
      console.error('Error getting ID token:', error);
    }
  };

  useEffect(() => {
    getIdToken();
    // callAPI();
  }, []);
  
  // You can access specific claims from the payload, e.g., email or username
  //console.log('Email:', payload.email);
  return (
    <div className="App">
      <Authenticator>
        {({ signOut }) => (
          <main>
            <header className='App-header'>
              {/* Quiz Component */}
              {/* <Quiz /> */}
              {/* Sign Out Button */}
              <button 
                onClick={signOut} 
                style={{ 
                  margin: '20px', 
                  fontSize: '0.8rem', 
                  padding: '5px 10px', 
                  marginTop: '20px'
                }}
              >
              {console.log('before calling getIdToken')}
               {/* {test()} */}
               {/* {callAPI()} */}
               {/* {getIdToken()} */}
               {console.log('after calling getIdToken')}
                Sign Out
              </button>
            </header>
          </main>
        )}
      </Authenticator>
    </div>
  );
}

export default withAuthenticator(App);